import React from "react";
import { inject, observer } from "mobx-react";
import PropTypes from "prop-types";
import { TabSwitcher } from "best-common-react";
import AwardComp from "./AwardComp";
import Comments from "./Comments";
import ContractComp from "./ContractComp";
import IndustryComp from "./IndustryComp";
import OneOnOneComp from "./OneOnOneComp";
import PlayerComp from "./PlayerComp";
import PerformanceComp from "./PerformanceComp";
import RankingsComp from "./RankingsComp";
import ReportHistory from "./ReportHistory";
import { RouteConstants } from "../../constants/RouteConstants";
import StatSummaryComp from "./StatSummaryComp";
import { TabSwitcherStyles } from "../../Theme";

class CompTabs extends React.Component {
  getTabs() {
    return [
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[0],
        component: <OneOnOneComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[1],
        component: <AwardComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[2],
        component: <RankingsComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[3],
        component: <StatSummaryComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[4],
        component: <IndustryComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[5],
        component: <PerformanceComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[6],
        component: <PlayerComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[7],
        component: <ContractComp />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[8],
        component: <ReportHistory />
      },
      {
        name: RouteConstants.EDIT_COMPARISON.tabs[9],
        component: <Comments />
      }
    ];
  }

  onSwitch(index) {
    let { compStore } = this.props.rootStore;
    compStore.displayStatWeights = index === 2;
    compStore.comp.scaleStatsNum = 162;
    compStore.selectedCompTabIndex = index;
  }

  render() {
    let { compStore } = this.props.rootStore;
    return (
      <React.Fragment>
        <TabSwitcher
          tabs={this.getTabs()}
          activeTabIndex={compStore.selectedCompTabIndex}
          onSwitch={index => {
            this.onSwitch(index);
          }}
          canChange={() => false}
          hasPadding={false}
          styles={TabSwitcherStyles}
        />
      </React.Fragment>
    );
  }
}

CompTabs.propTypes = {
  rootStore: PropTypes.object.isRequired
};

export default inject("rootStore")(observer(CompTabs));
