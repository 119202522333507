import React from "react";
import { action, autorun, extendObservable } from "mobx";
import _ from "lodash";
import Moment from "moment";
import { DateTimeFormatter } from "best-common-react";
import { AlertConstants } from "../../constants/AlertConstants";
import { CompConstants } from "../../constants/CompConstants";
import { LookupConstants } from "../../constants/LookupConstants";
import { RouteConstants } from "../../constants/RouteConstants";
import WidgetStore from "../WidgetStore";

class CompStore extends WidgetStore {
  constructor(authStore, routerStore, alertStore, lookupStore, reportStore, playerStore, statStore, piaApi) {
    super();
    this.authStore = authStore;
    this.routerStore = routerStore;
    this.alertStore = alertStore;
    this.lookupStore = lookupStore;
    this.reportStore = reportStore;
    this.playerStore = playerStore;
    this.statStore = statStore;
    this.piaApi = piaApi;
    this.resetStoreFlg = true;

    this.defaults = {
      tfDetail: {
        luTimeframetype: {},
        fromType: null,
        toType: null,
        id: {}
      },
      foldersLoaded: false,
      compSearch: {
        comparisonName: "",
        focusPlayerName: "",
        playerId: null,
        lastUpdatedFromDte: Moment(new Date())
          .subtract(1, "years")
          .format("MM/DD/YYYY")
          .toString(),
        lastUpdatedThroughDte: Moment(new Date())
          .format("MM/DD/YYYY")
          .toString()
      },
      compResults: [],
      showTimeframeDelete: false,
      standardCompIds: [],
      selectedComps: [],
      displayFolderId: -1,
      folders: [],
      moveToFolderId: 0,
      openFolderModal: false,
      comp: {
        comparisonId: 0,
        comparisonname: "Untitled Comparison",
        plMasters: [
          {
            className: "com.mlb.piaplus.db.PlMaster",
            playerlistId: 0,
            comparisononlyFlg: true,
            displayFirstContract: false,
            displayPyplusone: true,
            displaySalaryType: "LRD Salary",
            plDetails: []
          }
        ],
        slMasters: [
          {
            className: "com.mlb.piaplus.db.SlMaster",
            statlistId: 0,
            slDetails: []
          }
        ],
        prorateType: "actuals",
        prorateByPAs: "per600",
        showFirstOrLastContract: "F",
        usePyplusoneFlg: true,
        oneOnOneSalaryType: 1,
        oneOnOneSort: "PN",
        lrdOrBase: "LRD",
        cmpTimeframeMasters: [{ timeframeId: -1 }, { timeframeId: -1 }],
        summaryPageFlg: false,
        showOnRankingReport: "STATS",
        scale2020Num: 162,
        reportCriteria: {
          splitcategorylist: [],
          statSplits: [],
          selectedPlayerNameFormatCombined: "RLFM",
          onepageallstatsFlg: true,
          onestatperpageFlg: false,
          selectedForSummary: "avgRank",
          compReportCriteriaThresholdBeans: [{ selectedFlg: true, types: [] }],
          timeFrames: {
            OneonOne: { timeframeId: -1, tfDetail: [{ luTimeframetype: {} }] },
            Award: {
              timeframeId: -1,
              tfDetail: [
                {
                  luTimeframetype: { timeframetypeLk: "3", description: "Career" },
                  fromType: "Platform",
                  toType: "Platform"
                }
              ]
            },
            Ranking: {
              timeframeId: -1,
              tfDetail: [
                {
                  luTimeframetype: { timeframetypeLk: "1", description: "Single Year" },
                  fromType: "Platform"
                }
              ]
            },
            StatSummary: { timeframeId: -1, tfDetail: [{ luTimeframetype: {}, actualContractFlag: 0 }] },
            Industry: {
              timeframeId: -1,
              tfDetail: [
                {
                  luTimeframetype: { timeframetypeLk: "1", description: "Single Year" },
                  fromType: "Platform"
                }
              ]
            },
            Performance: {
              timeframeId: -1,
              tfDetail: [
                {
                  luTimeframetype: { timeframetypeLk: "1", description: "Single Year" },
                  fromType: "Platform"
                }
              ]
            },
            Player: {
              timeframeId: -1,
              tfDetail: [
                {
                  luTimeframetype: { timeframetypeLk: "1", description: "Single Year" },
                  fromType: "Platform"
                }
              ]
            }
          }
        }
      },
      compSplitCategory: {
        splitType: null,
        splits: []
      },
      compSplitName: "",
      selectedCompTabIndex: 0,
      compTimeFrames: [],
      timeFrameModalOpen: false,
      modalTimeFrame: { timeframeId: -1, tfDetail: [{ id: { detailId: 1 }, luTimeframetype: {} }] },
      futureYearSubModalOpen: false,
      displayStatWeights: false
    };

    extendObservable(this, {
      foldersLoaded: this.defaults["foldersLoaded"],
      compSearch: this.defaults["compSearch"],
      compResults: this.defaults["compResults"],
      standardCompIds: this.defaults["standardCompIds"],
      selectedComps: this.defaults["selectedComps"],
      displayFolderId: this.defaults["displayFolderId"],
      folders: this.defaults["folders"],
      moveToFolderId: this.defaults["moveToFolderId"],
      openFolderModal: this.defaults["openFolderModal"],
      comp: this.defaults["comp"],
      tfDetail: this.defaults["tfDetail"],
      compSplitCategory: this.defaults["compSplitCategory"],
      compSplitName: this.defaults["compSplitName"],
      selectedCompTabIndex: this.defaults["selectedCompTabIndex"],
      compTimeFrames: this.defaults["compTimeFrames"],
      timeFrameModalOpen: this.defaults["timeFrameModalOpen"],
      modalTimeFrame: this.defaults["modalTimeFrame"],
      futureYearSubModalOpen: this.defaults["futureYearSubModalOpen"],
      displayStatWeights: this.defaults["displayStatWeights"],
      showTimeframeDelete: this.defaults["showTimeframeDelete"],
      resetStore: action(() => {
        this.foldersLoaded = this.defaults["foldersLoaded"];
        this.compSearch = this.defaults["compSearch"];
        this.compResults = this.defaults["compResults"];
        this.standardCompIds = this.defaults["standardCompIds"];
        this.selectedComps = this.defaults["selectedComps"];
        this.displayFolderId = this.defaults["displayFolderId"];
        this.folders = this.defaults["folders"];
        this.moveToFolderId = this.defaults["moveToFolderId"];
        this.openFolderModal = this.defaults["openFolderModal"];
        this.comp = this.defaults["comp"];
        this.tfDetail = this.defaults["tfDetail"];
        this.compSplitCategory = this.defaults["compSplitCategory"];
        this.compSplitName = this.defaults["compSplitName"];
        this.selectedCompTabIndex = this.defaults["selectedCompTabIndex"];
        this.compTimeFrames = this.defaults["compTimeFrames"];
        this.timeFrameModalOpen = this.defaults["timeFrameModalOpen"];
        this.modalTimeFrame = this.defaults["modalTimeFrame"];
        this.futureYearSubModalOpen = this.defaults["futureYearSubModalOpen"];
        this.displayStatWeights = this.defaults["displayStatWeights"];
        this.showTimeframeDelete = this.defaults["showTimeframeDelete"];
      }),
      resetSearch: action(() => {
        this.compSearch = this.defaults["compSearch"];
      })
    });

    autorun(() => {
      if (!this.authStore.loggedIn) {
        this.resetStore();
      } else if (this.routerStore.isComparisonsPage) {
        this.onLoadComparisons();
      } else if (this.routerStore.isEditComparisonPage) {
        if (this.resetStoreFlg === true) {
          this.resetStore();
          this.parseEditCompUrl();
        } else {
          this.resetStoreFlg = true;
        }
      } else if (this.routerStore.isSharingPage) {
        this.getDefaultComps(false);
      }

      this.resetSearch();
    });
  }

  openComp(comparisonId, state) {
    localStorage.setItem("EDIT_COMP_STATE", JSON.stringify(state));
    this.routerStore.pushHistory(RouteConstants.EDIT_COMPARISON.route + "/" + comparisonId, false);
  }

  goToCompSearch(comparisonName) {
    this.resetStore();
    this.routerStore.pushHistory(RouteConstants.COMPARISONS.route + "/" + comparisonName, false);
  }

  onLoadComparisons = () => {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.COMPARISONS.route + RouteConstants.COMPARISONS.pathParams
    );

    if (!!pathParams["compName"]) {
      this.compSearch.comparisonName = pathParams["compName"];
      this.findComps();
    } else {
      this.getDefaultComps(true);
    }

    this.getCompFolders();
  };

  getCompCols() {
    return [
      {
        key: "comparisonname",
        name: "Comparison Name",
        width: 300,
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return (
            <a
              href="/#"
              onClick={event => {
                event.preventDefault();
                this.openComp(row.row.comparisonId);
              }}
            >
              {row.value}
            </a>
          );
        }
      },
      { key: "folder", name: "Folder", width: 180 },
      { key: "focusplayername", name: "Focus Player", width: 200 },
      { key: "source", name: "Source", width: 300 },
      {
        key: "lastchDtstmpAmericaNY",
        name: "Last Updated",
        // eslint-disable-next-line react/display-name
        formatter: row => {
          return <DateTimeFormatter format="MM/DD/YYYY - hh:mm a" value={row.value} />;
        }
      }
    ];
  }

  getDefaultComps = limitResultsFlag => {
    this.piaApi.getDefaultComparisons(limitResultsFlag).then(response => {
      if (!!response) {
        this.compResults = response;
        this.updateStandardCompIds();
      }
    });
  };

  findComps() {
    this.selectedComps = [];
    this.piaApi.findComparisons(this.compSearch).then(response => {
      if (!!response) {
        this.compResults = response;
        this.updateStandardCompIds();
      }
    });
  }

  updateStandardCompIds() {
    this.standardCompIds = _.filter(this.compResults, comp => comp.standardFlg).map(comp => comp.comparisonId);
  }

  get displayComps() {
    if (!this.compResults) {
      return [];
    }

    let filteredComps = _.filter(this.compResults, comp => {
      if (-1 === this.displayFolderId) {
        return true;
      } else {
        return comp.userFolder.folderId === this.displayFolderId;
      }
    });

    let selectedIds = this.selectedComps.map(comp => comp.comparisonId);

    return filteredComps.map(comp => {
      return {
        ...comp,
        isSelected: _.includes(selectedIds, comp.comparisonId),
        isStandard: _.includes(this.standardCompIds, comp.comparisonId)
      };
    });
  }

  addSelectedComps(rows) {
    rows.forEach(row => {
      if (row.comparisonId) {
        this.selectedComps.push(row);
      }
    });
  }

  removeUnselectedComps(rows) {
    this.selectedComps = _.differenceWith(
      this.selectedComps,
      rows,
      (selected, remove) => selected.comparisonId === remove.comparisonId
    );
  }

  showCheckBox(standardFlg) {
    return !standardFlg;
  }

  moveComps() {
    let selectedIds = this.selectedComps.map(comp => comp.comparisonId);
    this.piaApi.moveComparisons(this.moveToFolderId, selectedIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.onLoadComparisons();
      }
    });
  }

  deleteComps() {
    let selectedIds = this.selectedComps.map(comp => comp.comparisonId);
    this.piaApi.deleteComparisons(selectedIds).then(response => {
      if (!!response) {
        this.resetStore();
        this.onLoadComparisons();
      }
    });
  }

  updateStandardComps() {
    this.piaApi.updateStandardComps(this.standardCompIds).then(response => {
      if (!!response) {
        this.getDefaultComps(false);
      }
    });
  }

  shareComps(users, comments) {
    let sharing = {
      comparisons: this.selectedComps,
      users: users,
      comments: comments
    };
    this.piaApi.shareComps(sharing).then(response => {
      if (!!response) {
        this.selectedComps = [];
        this.getDefaultComps(false);
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "The comparisons you selected were successfully shared."
        });
      }
    });
  }

  getCompFolders = () => {
    this.foldersLoaded = false;
    this.piaApi.getComparisonFolders().then(response => {
      if (!!response) {
        this.folders = response;
        this.foldersLoaded = true;
      }
    });
  };

  parseEditCompUrl = () => {
    const pathParams = this.routerStore.getPathParams(
      RouteConstants.EDIT_COMPARISON.route + RouteConstants.EDIT_COMPARISON.pathParams
    );

    let comparisonId = pathParams["comparisonId"] || this.defaults["comp"].comparisonId;
    comparisonId = comparisonId * 1;

    this.loadCompTimeFrames();

    this.lookupStore.lookupsPromise.then(lkresp => {
      this.loadComp(comparisonId);
    });
  };

  loadCompTimeFrames = () => {
    this.compTimeFrames = this.defaults["compTimeFrames"];

    let timeFrames = this.lookupStore.getLookup(LookupConstants.USER_TIMEFRAMES);

    if (timeFrames) {
      this.compTimeFrames = _.cloneDeep(timeFrames);
    }
  };

  loadComp(id) {
    this.piaApi.loadComparison(id).then(response => {
      if (!!response) {
        if (!response.plMasters || response.plMasters.length === 0) {
          response.plMasters = this.defaults["comp"].plMasters;
        }

        if (!response.slMasters || response.slMasters.length === 0) {
          response.slMasters = this.defaults["comp"].slMasters;
        }

        let compState = null;

        try {
          compState = JSON.parse(localStorage.getItem("EDIT_COMP_STATE"));
        } catch (error) {
          compState = null;
        }

        if (compState) {
          if (compState.prorateType) {
            response.prorateType = compState.prorateType;
          }

          if (compState.oneOnOneSalaryType) {
            response.oneOnOneSalaryType = compState.oneOnOneSalaryType;
          }

          if (compState.reportCriteria) {
            response.reportCriteria = compState.reportCriteria;
          }

          if (compState.plMasters && compState.plMasters.length > 0) {
            response.plMasters = compState.plMasters;
          }

          if (compState.slMasters && compState.slMasters.length > 0) {
            response.slMasters = compState.slMasters;
          }
        }

        //find user's default timeframe
        let oneOnOneDefault = this.defaults["comp"].reportCriteria.timeFrames.OneonOne;
        let statSummaryDefault = this.defaults["comp"].reportCriteria.timeFrames.StatSummary;
        if (id === 0) {
          this.compTimeFrames.forEach(tf => {
            if (tf.oneOnOneDefaultFlg === true) {
              oneOnOneDefault = tf;
            }
            if (tf.statSummaryDefaultFlg === true) {
              statSummaryDefault = tf;
            }
          });
        } else {
          var oneOnOne = response.cmpTimeframeMasters.filter(
            tf => tf.timeframereporttypeLk && tf.timeframereporttypeLk.timeframereporttypeLk === "1"
          )[0];
          var compOneOnOneDefault = this.compTimeFrames.filter(tf =>
            oneOnOne.timeframeName === "PIA+ Default"
              ? tf.timeframeId === -1
              : tf.timeframeName === oneOnOne.timeframeName
          )[0];

          var statSumm = response.cmpTimeframeMasters.filter(
            tf => tf.timeframereporttypeLk && tf.timeframereporttypeLk.timeframereporttypeLk === "3"
          )[0];
          var compStatSummaryDefault = this.compTimeFrames.filter(tf =>
            statSumm.timeframeName === "PIA+ Default"
              ? tf.timeframeId === -2
              : tf.timeframeName === statSumm.timeframeName
          )[0];

          if (compOneOnOneDefault) {
            response.reportCriteria.timeFrames[CompConstants.TABS.ONE_ON_ONE] = compOneOnOneDefault;
          }

          if (compStatSummaryDefault) {
            response.reportCriteria.timeFrames[CompConstants.TABS.STAT_SUMMARY] = compStatSummaryDefault;
          }
        }

        if (!response.reportCriteria.timeFrames[CompConstants.TABS.ONE_ON_ONE]) {
          response.reportCriteria.timeFrames[CompConstants.TABS.ONE_ON_ONE] = oneOnOneDefault;
        }

        if (!response.reportCriteria.timeFrames[CompConstants.TABS.STAT_SUMMARY]) {
          response.reportCriteria.timeFrames[CompConstants.TABS.STAT_SUMMARY] = statSummaryDefault;
        }

        if (
          !response.reportCriteria.compReportCriteriaThresholdBeans ||
          response.reportCriteria.compReportCriteriaThresholdBeans.length === 0
        ) {
          response.reportCriteria.compReportCriteriaThresholdBeans = this.defaults[
            "comp"
          ].reportCriteria.compReportCriteriaThresholdBeans;
        }

        this.comp = response;

        // If we came from the comp finder, send that to the conversion endpoint
        if (compState && compState.convertFromCompFinder) {
          let converter = {
            compFinder: compState.cfMaster,
            plMaster: compState.plMaster,
            comparison: this.comp
          };

          this.piaApi.addCompFinderToComparison(converter).then(convertedResponse => {
            this.comp = convertedResponse;
          });
        }

        localStorage.removeItem("EDIT_COMP_STATE");
      }

      this.loadTabTimeFrame(CompConstants.TABS.AWARD);
      this.loadTabTimeFrame(CompConstants.TABS.RANKINGS);
      this.loadTabTimeFrame(CompConstants.TABS.INDUSTRY);
      this.loadTabTimeFrame(CompConstants.TABS.PERFORMANCE);
      this.loadTabTimeFrame(CompConstants.TABS.PLAYER);
    });
  }

  loadTabTimeFrame(tab) {
    if (!this.comp.reportCriteria.timeFrames[tab]) {
      this.comp.reportCriteria.timeFrames[tab] = this.defaults["comp"].reportCriteria.timeFrames[tab];
    }
  }

  getPlayerList(id) {
    this.piaApi.getPlayerList(id).then(response => {
      if (!!response) {
        this.comp.plMasters[0] = response;
      }
    });
  }

  setFocusPlayer(player) {
    this.comp.plMasters[0].focusplayerId = player.playerid;
    this.comp.plMasters[0].focusplayername = player.playername;
    this.comp.plMasters[0].focusplayerYear = player.platformYear;

    this.comp.plMasters[0].plDetails.forEach(pld => {
      pld.focusplayerFlg = false;

      if (pld.playerid === player.playerid) {
        pld.focusplayerFlg = true;
      }
    });
  }

  getStatList(id) {
    this.piaApi.getStatList(id).then(response => {
      if (!!response) {
        this.comp.slMasters[0] = response;
      }
    });
  }

  get displayPlayerListDetails() {
    if (!this.comp.plMasters[0].plDetails) {
      return [];
    }

    return this.comp.plMasters[0].plDetails.map(player => {
      return {
        ...player,
        selectedoncompFlg: !!player.selectedoncompFlg ? player.selectedoncompFlg : false
      };
    });
  }

  get displayStatListDetails() {
    if (!this.comp.slMasters[0].slDetails) {
      return [];
    }

    return this.comp.slMasters[0].slDetails.map((stat, index) => {
      return {
        ...stat,
        index,
        selectedoncompFlg: !!stat.selectedoncompFlg ? stat.selectedoncompFlg : false
      };
    });
  }

  get displaySplitCategories() {
    if (!this.comp || !this.comp.reportCriteria || !this.comp.reportCriteria.splitcategorylist) {
      return [];
    }

    let addedCategories = this.comp.reportCriteria.statSplits.map(split => split.splitCategory);

    return _.filter(
      this.comp.reportCriteria.splitcategorylist,
      category => !_.includes(addedCategories, category.splitType)
    ).map(category => {
      return {
        ...category,
        splits: [{ splitName: "View All" }].concat(category.splits)
      };
    });
  }

  get displayCompSplits() {
    if (!this.comp || !this.comp.reportCriteria || !this.comp.reportCriteria.statSplits) {
      return [];
    }

    return this.comp.reportCriteria.statSplits;
  }

  addSplit() {
    if (this.compSplitCategory && this.compSplitName && "" !== this.compSplitName) {
      this.comp.reportCriteria.statSplits.push({
        splitCategory: this.compSplitCategory.splitType,
        splitName: this.compSplitName
      });

      this.compSplitCategory = this.defaults["compSplitCategory"];
      this.compSplitName = this.defaults["compSplitName"];
    }
  }

  openTimeFrameModal(tab, newTfFlag = false) {
    let modalTf = _.cloneDeep(this.comp.cmpTimeframeMasters[0]);
    let detailTemplate = _.cloneDeep(modalTf.tfDetail[0]);

    if (newTfFlag) {
      if (CompConstants.TABS.STAT_SUMMARY === tab) {
        modalTf = _.cloneDeep(this.comp.cmpTimeframeMasters[1]);
        detailTemplate = _.cloneDeep(modalTf.tfDetail[0]);
      }

      modalTf.timeframeId = null;
      detailTemplate.id.timeframeId = null;
    } else {
      modalTf = _.cloneDeep(this.comp.reportCriteria.timeFrames[tab]);
    }

    modalTf.detailTemplate = detailTemplate;
    modalTf.timeframeId = null;
    modalTf.timeframeName = "";
    this.modalTimeFrame = modalTf;
    this.timeFrameModalOpen = true;
  }

  saveTimeFrame(tfMaster, tab) {
    tfMaster.comparisonId = this.comp.comparisonId;
    this.piaApi.saveComparisonTimeFrame(tfMaster, tab).then(response => {
      if (response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Timeframe saved successfully"
        });
        this.resetStoreFlg = false;
        let callback = () => {
          this.reloadTimeFrame(response, tab);
        };
        this.lookupStore.reloadLookups(callback);
      }
    });
    let index = this.comp.cmpTimeframeMasters.findIndex(tf => tf.timeframereporttypeLk.timeframereporttypeLk == "1");
    this.comp.cmpTimeframeMasters[index] = tfMaster;
  }

  postResetTfActions() {
    this.alertStore.addAlert({
      type: AlertConstants.TYPES.SUCCESS,
      text: "Timeframe default successfully reset."
    });
    this.lookupStore.reloadLookups(this.loadCompTimeFrames);
  }

  reloadTimeFrame = (tf, tab) => {
    this.loadCompTimeFrames();
    this.comp.reportCriteria.timeFrames[tab] = tf;
  };

  deleteTimeFrame(tab) {
    this.piaApi.deleteComparisonTimeFrame(this.modalTimeFrame).then(response => {
      if (response) {
        this.alertStore.addAlert({
          type: AlertConstants.TYPES.SUCCESS,
          text: "Timeframe deleted successfully"
        });
        this.showTimeframeDelete = false;
        this.lookupStore.reloadLookups(this.loadCompTimeFrames);
      }
    });
    let oneOnOneDefault;
    this.compTimeFrames
      .filter(tf => tf.timeframeId !== this.modalTimeFrame.timeframeId)
      .forEach(tf => {
        if (tf.oneOnOneDefaultFlg === true) {
          oneOnOneDefault = tf;
        }
      });
    if (oneOnOneDefault === null) {
      oneOnOneDefault = this.compTimeFrames.find(tf => tf.timeframeId === -1);
    }
    this.comp.reportCriteria.timeFrames[tab] = oneOnOneDefault;
  }

  saveComp(overrideFlag = false) {
    this.piaApi.saveComparison(this.comp, overrideFlag).then(response => {
      if (response) {
        if (response.overrideMessage) {
          this.comp.overrideMessage = response.overrideMessage;
          this.comp.overrideFn = () => {
            this.comp.overrideMessage = null;
            this.comp.showOverride = false;
            this.saveComp(true);
          };
          this.comp.showOverride = true;
        } else if (response.payload) {
          this.openComp(response.payload);
        }
      }
    });
  }

  deleteComp() {
    this.piaApi.deleteComparisons([this.comp.comparisonId]).then(response => {
      if (!!response) {
        if (!!response) {
          this.openComp(0);
        }
      }
    });
  }

  runReport(type, options = { cmpMaster: this.comp }) {
    this.piaApi.validateReport(type, options.cmpMaster).then(response => {
      if (response) {
        if (response.overrideMessage) {
          this.comp.overrideMessage = response.overrideMessage;
          this.comp.overrideFn = () => {
            this.comp.overrideMessage = null;
            this.comp.showOverride = false;
            this.reportStore.launchReport(type, options);
          };
          this.comp.showOverride = true;
        } else if (response.payload) {
          this.comp.overrideMessage = null;
          this.comp.showOverride = false;
          this.reportStore.launchReport(type, options);
        }
      }
    });
  }
}

export default CompStore;
